<template>
    <div class="mixed-chart">
        <div class="legend">
            <div
                v-for="(metric, i) in metricKeys"
                :key="`metric-${i+1}`">
                <span :class="`metric-${i+1}`"><span class="metric-name">{{ metrics[metric].name }}</span></span>
            </div>
        </div>
        <div class="legend-side">
            <div
                v-for="(metric, i) in metricKeys"
                :key="`metric-${i+1}`">
                <span :class="`metric-${i+1}`"><span class="metric-name">{{ metrics[metric].name }}</span></span>
            </div>
        </div>
        <apex-chart
            :height="chartHeight"
            :options="options"
            :series="series" />
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import { numeralFormatWithMinValue } from '@/helpers/numeralFormats';

export default {
    components: {
        ApexChart
    },
    props: {
        metrics: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            options: {
                chart: { toolbar: { show: false } },
                legend: { show: false },
                colors: ['#007FB8', '#7EDDFE'],
                grid: {
                    borderColor: '#CCCCCC',
                    strokeDashArray: 4
                },
                stroke: {
                    width: 0
                },
                plotOptions: {
                    bar: {
                        borderRadius: 3,
                        borderRadiusApplication: 'end'
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        trim: true,
                        style: {
                            colors: '#4E555B'
                        }
                    }
                },
                yaxis: [
                    {
                        labels: {
                            formatter: (val) => { return this.formatFirstMetric(val) }
                        }
                    },
                    {
                        opposite: true,
                        labels: {
                            formatter: (val) => { return this.formatSecondMetric(val) }
                        }
                    }
                ]
            }
        };
    },
    computed: {
        chartHeight() {
            switch (this.size) {
                case 'full':
                    return 550;
                default:
                    return 250;
            }
        },
        metricKeys() {
            return Object.keys(this.metrics);
        },
        series() {
            return this.metricKeys.map(key => {
                return {
                    name: this.metrics[key].name,
                    data: this.metrics[key].series,
                    type: 'column'
                };
            });
        }
    },
    methods: {
        formatFirstMetric(val) {
            const firstMetric = Object.entries(this.metrics)[0] ?? null;
            if (firstMetric) { return numeralFormatWithMinValue(val, firstMetric[1].format) }
            return val;
        },
        formatSecondMetric(val) {
            const secondMetric = Object.entries(this.metrics)[1] ?? null;
            if (secondMetric) { return numeralFormatWithMinValue(val, secondMetric[1].format) }
            return val;
        }
    }
};
</script>

<style lang="scss" scoped>
.mixed-chart {
    position: relative;
}
.legend {
    display: flex;
    justify-content: center;
    transform: translateY(15px);
    .metric-1,
    .metric-2 {
        display: block;
        white-space: nowrap;
        font-size: 11px;
        margin-right: 30px;
        position: relative;
        .metric-name::after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #007FB8;
            top: 3px;
            left: -15px;
        }
    }
    .metric-2 .metric-name::after {
        background: #7EDDFE;
    }
}
.legend-side {
    .metric-1,
    .metric-2 {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        font-size: 11px;
        .metric-name::after {
            content: '';
            display: block;
            position: relative;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #007FB8;
            top: -12px;
            left: -15px;
        }
    }
    .metric-1 {
        transform: rotate(-90deg);
        left: 0;
    }
    .metric-2 {
        transform: rotate(90deg);
        right: 0;
        .metric-name::after {
            background: #7EDDFE;
        }
    }
}
</style>